import { createSelector } from 'reselect'

import { TransactionModel } from '../../model'
import { State } from '../../reducers'

export const selectCurrentTradingHistoryElement = createSelector(
  (state: State) => state.tradingHistory.currentTradingHistory,
  (currentTradingHistory): TransactionModel | undefined => {
    return currentTradingHistory?.length ? currentTradingHistory[0] : undefined
  }
)

export const selectCurrentPoolActivityElement = createSelector(
  (state: State) => state.poolActivity.currentPoolActivity,
  (currentPoolActivity): TransactionModel | undefined =>
    currentPoolActivity?.length ? currentPoolActivity[0] : undefined
)
