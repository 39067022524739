import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { ChartRowV3 } from '../../model'
import { State } from '../../reducers'
import { getCurrencyName, pickValueBasedOnCurrency } from '../../utils'
import AnimatedNumber from '../AnimatedNumber'
import ChartCompact from '../Chart/ChartCompact'
import Delta from '../Delta'
import { Heading } from '../Heading'
import { calculateTopPartValue, getAnimatedColor } from './helpers'
import { selectCurrentPoolActivityElement, selectCurrentTradingHistoryElement } from './selectors'
import { SidebarType } from './sidebarUtils'

interface SidebarHeaderProps {
  type: SidebarType
  url?: string
}

const SidebarHeader: FC<SidebarHeaderProps> = ({ type, url }) => {
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const dataChart = useSelector((state: State) => state.dataChart)

  const [liquidityChartData, setLiquidityChartData] = useState<number[]>([])
  const [volumeChartData, setVolumeChartData] = useState<number[]>([])
  const [date, setDate] = useState<number[]>([])
  const [color, setColor] = useState<string | undefined>()
  const selectedCurrency = useSelector((state: State) => state.currency)
  const isMobile = useSelector((state: State) => state.isMobile)
  const currentTradingHistoryElement = useSelector(selectCurrentTradingHistoryElement)
  const currentPoolActivityElement = useSelector(selectCurrentPoolActivityElement)

  const topPartValue = calculateTopPartValue(type, selectedCurrency, currentToken)

  const generateChartData = useCallback((): void => {
    if (dataChart) {
      const liquidityChartData: number[] = []
      const volumeChartData: number[] = []
      const date: number[] = []
      dataChart.forEach((item: ChartRowV3) => {
        liquidityChartData.push(item.totalLiquidity)
        volumeChartData.push(item.dailyVolume)
        date.push(item.date)
      })
      setLiquidityChartData(liquidityChartData)
      setVolumeChartData(volumeChartData)
      setDate(date)
    }
  }, [dataChart])

  useEffect(() => {
    const animatedColor = getAnimatedColor(
      currentTradingHistoryElement,
      currentPoolActivityElement,
      currentToken,
      type
    )
    setColor(animatedColor)
  }, [currentTradingHistoryElement, currentPoolActivityElement, currentToken, type])

  useEffect(() => {
    generateChartData()
  }, [dataChart, generateChartData])

  if (!currentToken) {
    return null
  }

  const currencyDisplayName = getCurrencyName(currentToken.network, selectedCurrency)
  const volumeChange24h = pickValueBasedOnCurrency(
    selectedCurrency,
    currentToken.volumeUSDChange24h,
    currentToken.volumeETHChange24h
  )
  const liquidityChange24h = pickValueBasedOnCurrency(
    selectedCurrency,
    currentToken.liquidityUSDChange24h,
    currentToken.liquidityETHChange24h
  )
  const dataTip = `Click to see more info about the token's ${
    type === SidebarType.liquidity ? 'liquidity' : 'trading volume'
  }`

  return (
    <>
      <Link
        to={url}
        className="sidebar__header"
        data-tip={!isMobile ? dataTip : undefined}
        data-for="app-tooltip"
        data-place={type === SidebarType.liquidity ? 'right' : 'left'}>
        <Heading size="md" className="sidebar__title">
          {type === SidebarType.liquidity ? 'Token Liquidity' : 'Trading Volume, 24h'}
        </Heading>
        <div className="sidebar__value">
          {selectedCurrency === 'USD' && <span className="sign">$</span>}
          <span className="value">
            <AnimatedNumber value={topPartValue || 0} color={color} />
          </span>
          {selectedCurrency !== 'USD' && <span className="sign">&nbsp;{currencyDisplayName}</span>}
          {currentToken && (
            <>
              <Delta
                value={
                  (type === SidebarType.liquidity ? liquidityChange24h : volumeChange24h) * 100
                }
                size="md"
              />
            </>
          )}
        </div>
      </Link>
      {!isMobile && (
        <div className="sidebar__chart">
          <div className="chart">
            <ChartCompact
              labels={date}
              data={type === SidebarType.liquidity ? liquidityChartData : volumeChartData}
              color={type === SidebarType.liquidity ? '#FF646D' : '#46A6FF'}
              bg={
                type === SidebarType.liquidity
                  ? ['rgba(169, 18, 163, 0.5)', 'rgba(91, 127, 255, 0.5)']
                  : ['rgba(255, 0, 153, 0.5)', 'rgba(70, 89, 255, 0.5)']
              }
              currency={currencyDisplayName}
              logarithmic={true}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default SidebarHeader
