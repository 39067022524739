import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { usePrevious } from '../hooks'
import { isTitleNaN, numberWithCommas } from '../utils'

interface SlideProps {
  value: string
  className?: string
}

export default function Slide(props: SlideProps): React.ReactElement {
  const [animate, setAnimate] = useState(false)
  const previousValue = usePrevious(props.value)

  useEffect(() => {
    if (props.value === previousValue) {
      return
    }
    setAnimate(true)

    const timeout = setTimeout(() => {
      setAnimate(false)
    }, 700)

    return (): void => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]) // there should be NO previousValue in dependencies

  return (
    <div
      className={classNames('slide', { 'slide--animate': animate }, props.className)}
      title={isTitleNaN(numberWithCommas(props.value))}>
      <div className="slide__value slide__value--current">{props.value || '0'}</div>
      <div className="slide__value slide__value--previous">{props.value}</div>
    </div>
  )
}
