import { useWeb3React } from '@telekomconsalting/core'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import SimpleBar from 'simplebar-react'

import { updateSettings } from '../../actions'
import { AmplitudeEvent } from '../../constants/amplitudeEvents'
import { MarketType } from '../../model/tokenModel'
import { State } from '../../reducers'
import amplitudeService from '../../services/amplitudeService'
import Button from '../buttons/Button'
import CloseButton from '../buttons/CloseButton'
import { FormVariants } from '../Form/helpers'
import LimitOrders from '../LimitOrders/LimitOrders'
import MarketScreener from '../MarketScreener/MarketScreener'
import PoolActivity from '../PoolActivity/PoolActivity'
import TradingHistory from '../TransactionHistory/TradingHistory'
import SidebarHeader from './SidebarHeader'
import { SidebarType } from './sidebarUtils'

interface SidebarProps {
  type: SidebarType
  isMobile: boolean
  position?: 'left' | 'right'
}

export default function Sidebar(props: SidebarProps): React.ReactElement {
  const reduxDispatch = useDispatch()
  const drawersSide = props.position === 'left' ? 'isLeftDrawerOpen' : 'isRightDrawerOpen'
  const otherDrawer = props.position === 'left' ? 'isRightDrawerOpen' : 'isLeftDrawerOpen'
  // NOTE: Do not call setOpen but updateOpen so amplitude is called
  const { account } = useWeb3React()
  const [isOpen, setOpen] = useState(true)
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const walletAddress = useSelector((state: State) => state.traderProfile.walletAddress)
  const settings = useSelector((state: State) => state.settings.settingsData)
  const openSidebar = useSelector((state: State) => state.ui.isSidebarOpen)

  const { isMobile } = useSelector((state: State) => state)
  const { tradeformType } = useSelector((state: State) => state.ui)

  const updateOpen = (value: boolean): void => {
    if (!value && (props.position === 'left' || props.type === 'liquidity')) {
      amplitudeService.sendEvent(AmplitudeEvent.COLLAPSED_LEFT_LIQUIDITY_SIDEBAR)
    }

    if (!value && (props.position === 'right' || props.type === 'history')) {
      amplitudeService.sendEvent(AmplitudeEvent.COLLAPSED_RIGHT_TRADING_SIDEBAR)
    }

    setOpen(value)
  }

  useEffect(() => {
    // Do not spam amplitude
    if (settings[drawersSide] !== isOpen) {
      updateOpen(settings[drawersSide])
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21252
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.isLeftDrawerOpen, settings.isRightDrawerOpen])

  const callGtmService = (condition: boolean): void => {
    condition
      ? amplitudeService.interactedWithLeftSidebar()
      : amplitudeService.interactedWithRightSidebar()
  }

  const shouldRenderSidebar = (openSidebar === 'none' || props.isMobile) && !walletAddress
  const sideClassName = props.position === 'left' ? 'sidebar--left' : 'sidebar--right'
  const openClassName = isOpen ? 'opened' : 'closed'
  const fullSidebarUrl = `/token/${currentToken?.id}/${props.type}`

  if (!shouldRenderSidebar) {
    return <div />
  }

  const sidebarContent = (
    <div className="sidebar__content">
      {props.type === SidebarType.liquidity && openSidebar === SidebarType.none && (
        <PoolActivity fullSidebarUrl={fullSidebarUrl} />
      )}
      {props.type === SidebarType.history && openSidebar === SidebarType.none && (
        <>
          <MarketScreener />
          {process.env.REACT_APP_LIMIT_ORDERS === 'true' &&
            tradeformType === FormVariants.limit &&
            account && <LimitOrders />}
          <TradingHistory fullSidebarUrl={fullSidebarUrl} />
        </>
      )}
    </div>
  )
  const handleSidebarShow = (): void => {
    reduxDispatch(updateSettings({ [drawersSide]: !isOpen, isChartFullScreen: false }))
    updateOpen(!isOpen)
    callGtmService(props.position === 'left')
  }

  const handleSidebarHide = (): void => {
    const newOpenValue = !isOpen
    reduxDispatch(
      updateSettings({
        [drawersSide]: newOpenValue,
        isChartFullScreen: !settings[otherDrawer],
      })
    )
    updateOpen(!isOpen)
    callGtmService(props.position === 'left')
  }

  return (
    <div className={cn('sidebar', sideClassName, openClassName)}>
      <div
        className="sidebar__wrapper"
        onClick={(): void => callGtmService(props.type === SidebarType.liquidity)}>
        <SidebarHeader type={props.type} url={fullSidebarUrl} />
        {(currentToken?.marketType !== MarketType.lp || props.type !== SidebarType.history) && (
          <Button className="sidebar__show" onClick={handleSidebarShow} />
        )}
        {!isMobile && (
          <>
            <CloseButton size="sm" className="sidebar__hide" onClick={handleSidebarHide} />
            <Link
              to={fullSidebarUrl}
              id={`${props.type}-sidebar-open`}
              type="button"
              className="sidebar__open"
            />
          </>
        )}
        <div className="sidebar__body">
          {!isMobile && (
            <SimpleBar
              scrollableNodeProps={{
                onScroll: (): void => {
                  ReactTooltip.hide()
                },
              }}>
              {sidebarContent}
            </SimpleBar>
          )}
          {isMobile && sidebarContent}
        </div>
      </div>
    </div>
  )
}
