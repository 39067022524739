import { model } from '@telekomconsalting/dex-guru-model'
import * as H from 'history'

import { MIN_TX_SIDEBAR__FULL } from '../../config/settings'
import { getHeightElement } from '../../helpers/dom/getHeightElement'
import { Address, TransactionModel, TransactionModelV2 } from '../../model'

export enum SidebarType {
  liquidity = 'liquidity',
  history = 'history',
  none = 'none',
}

const HEIGHT_OF_ROW = 48

export const calculateRowCount = (content?: Element | null, thead?: Element | null): number => {
  const contentHeight = getHeightElement(content)
  const theadHeight = getHeightElement(thead)
  const tbodyHeight = contentHeight - theadHeight
  const quantity = Math.floor(tbodyHeight / HEIGHT_OF_ROW)
  return quantity < MIN_TX_SIDEBAR__FULL ? MIN_TX_SIDEBAR__FULL : quantity
}

export const isTraderUrl = (history: H.History): boolean =>
  history.location.pathname.includes('/trader/')

export const isOwnTx = (addresses: string[] | null, account?: Address | null): boolean =>
  !!account && Array.isArray(addresses) && addresses.includes(account.toLowerCase())

// https://app.shortcut.com/dexguru/story/16241/create-feature-flag-for-v3-transactions
export const getUniversalTransactionsModel = (data: TransactionModelV2[]): TransactionModel[] => {
  return data.map((item) => {
    if (item.transaction_type === 'swap') {
      const { amount0In, amount0Out, amount1In, amount1Out } = item
      const amount0 = amount0In && amount0In > 0 ? amount0In : amount1In
      const amount1 = amount0Out && amount0Out > 0 ? amount0Out : amount1Out
      const symbol0 = amount0In && amount0In > 0 ? item.token0Symbol : item.token1Symbol
      const symbol1 = amount0Out && amount0Out > 0 ? item.token0Symbol : item.token1Symbol
      const token0 = amount0In && amount0In > 0 ? item.token0Address : item.token1Address
      const token1 = amount0Out && amount0Out > 0 ? item.token0Address : item.token1Address
      const price0USD = amount0In && amount0In > 0 ? item.token0PriceUSD : item.token1PriceUSD
      const price1USD = amount0Out && amount0Out > 0 ? item.token0PriceUSD : item.token1PriceUSD
      const price0ETH = amount0In && amount0In > 0 ? item.token0PriceETH : item.token1PriceETH
      const price1ETH = amount0Out && amount0Out > 0 ? item.token0PriceETH : item.token1PriceETH
      return {
        type: item.AMM,
        network: item.network,
        timestamp: item.timestamp,
        transactionType: item.transaction_type,
        transactionAddress: item.transactionAddress,
        tokenAddresses: [token0, token1],
        symbols: [symbol0, symbol1],
        wallets: [item.walletAddress],
        walletsCategories: [item.walletCategory] as model.Category[],
        amounts: [amount0, amount1],
        amountStable: item.amountUSD,
        amountNative: item.amountETH,
        amountsStable: [item.amountUSD],
        amountsNative: [item.amountETH],
        pricesStable: [price0USD, price1USD],
        pricesNative: [price0ETH, price1ETH],
        poolAddress: item.pairAddress,
        fromAddress: token0,
        toAddress: token1,
      }
    }
    if (item.transaction_type === 'transfer') {
      return {
        type: 'native',
        network: item.network,
        timestamp: item.timestamp,
        transactionType: item.transaction_type,
        transactionAddress: item.transactionAddress,
        tokenAddresses: [item.tokenAddress],
        symbols: [item.symbol],
        wallets: [item.fromWalletAddress, item.toWalletAddress],
        walletsCategories: null,
        amounts: [item.amount],
        amountStable: item.amountUSD,
        amountNative: null,
        amountsStable: [item.amountUSD],
        amountsNative: null,
        pricesStable: [1.7139247767239896],
        pricesNative: null,
        poolAddress: null,
        fromAddress: item.fromWalletAddress,
        toAddress: item.toWalletAddress,
      }
    }
    return {
      type: item.AMM,
      network: item.network,
      timestamp: item.timestamp,
      transactionType: item.transaction_type,
      transactionAddress: item.transactionAddress,
      tokenAddresses: [item.token0Address, item.token1Address],
      symbols: [item.token0Symbol, item.token1Symbol],
      wallets: [item.walletAddress],
      walletsCategories: [item.walletCategory] as model.Category[],
      amounts: [item.amount0, item.amount1],
      amountStable: item.amountUSD,
      amountNative: item.amountETH,
      amountsStable: [item.amount0USD, item.amount1USD],
      amountsNative: [item.amount0ETH, item.amount1ETH],
      pricesStable: [item.amount0USD, item.amount1USD],
      pricesNative: [item.amount0ETH, item.amount1ETH],
      poolAddress: item.pairAddress,
      fromAddress: null,
      toAddress: null,
    }
  })
}
