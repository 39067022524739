import { TokenV3, TransactionModel } from '../../model'
import { getTokenAddress } from '../../utils'
import { SidebarType } from './sidebarUtils'

export const calculateTopPartValue = (
  type: SidebarType,
  selectedCurrency: string,
  currentToken?: TokenV3
): number | null => {
  if (!currentToken || type === 'none') {
    return null
  }

  return type === 'liquidity'
    ? selectedCurrency === 'USD'
      ? currentToken.liquidityUSD
      : currentToken.liquidityETH
    : selectedCurrency === 'USD'
    ? currentToken.volume24hUSD
    : currentToken.volume24hETH
}

export const getAnimatedColor = (
  currentTradingHistoryElement?: TransactionModel,
  currentPoolActivityElement?: TransactionModel,
  currentToken?: TokenV3,
  type?: SidebarType
): string | undefined => {
  if (!type || type === 'none') {
    return undefined
  }

  if (type === 'history' && currentTradingHistoryElement) {
    if (!currentToken) {
      return 'transparent'
    }

    const currentTokenAddress = getTokenAddress(currentToken).toLowerCase()
    const fromAddress = currentTradingHistoryElement.fromAddress

    return currentTokenAddress === fromAddress ? 'red' : 'green'
  }
  if (type === 'liquidity' && currentPoolActivityElement) {
    return Object(currentPoolActivityElement).transaction_types === 'mint' ? 'green' : 'red'
  }
}
