import React, { FC, useRef, useState } from 'react'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'

import { getRootElement } from '../../helpers/appHelpers'
import { useComponentDidMount } from '../../hooks/useComponentDidMount'
import useElementHeight from '../../hooks/useElementHeight'
import { FullSidebarsModifier } from '../../model'
import { State } from '../../reducers'
import Button from '../buttons/Button'
import SelectHistory from '../SelectHistory'
import ModalEmojiDetails from '../TableWithFilter/ModalEmojiDetails'

interface SidebarHeaderProps {
  modifier: FullSidebarsModifier
  option: number
  onChangeOption: (value: number) => void
  onChangeTHeadHeight: (theadHeight: number) => void
  isHide: boolean
}

const SidebarHistoryHeader: FC<SidebarHeaderProps> = ({
  modifier,
  option,
  isHide,
  onChangeOption,
  onChangeTHeadHeight,
}) => {
  const historyContentTHeadRef = useRef<HTMLDivElement>(null)
  // calculate heigh of .history-content__thead
  useElementHeight<HTMLDivElement>(historyContentTHeadRef, onChangeTHeadHeight)

  const [isOpenEmojiDetails, setIsOpenEmojiDetails] = useState<boolean>(false)
  const { isMobile } = useSelector((state: State) => state)

  useComponentDidMount(() => {
    Modal.setAppElement('body')
  })

  const openEmojiDetails = (): void => {
    setIsOpenEmojiDetails(true)
  }

  const closeEmojiDetails = (): void => {
    setIsOpenEmojiDetails(false)
  }

  if (isHide) {
    return <div className="history-content__thead" ref={historyContentTHeadRef} />
  }

  return (
    <div className="history-content__thead" ref={historyContentTHeadRef}>
      <div className="history-content__trow">
        <div className="type" />
        <div className="own" />
        <div className="amount">
          <span className="caption">Token Amount</span>
        </div>
        <div className="token" />
        <div className="price">
          {modifier === 'trading-history' ? (
            <SelectHistory
              values={['Token Value', 'Token Price']}
              onSelect={onChangeOption}
              option={option}
            />
          ) : (
            <span className="caption">Token Value</span>
          )}
        </div>
        <div className="emoji">
          {isMobile && (
            <>
              <Button
                title="Details"
                className="button button--sm button--info"
                onClick={openEmojiDetails}
              />
              <Modal
                parentSelector={getRootElement}
                isOpen={isOpenEmojiDetails}
                onRequestClose={closeEmojiDetails}
                overlayClassName="modal-overlay"
                className="modal-content">
                <ModalEmojiDetails onClose={closeEmojiDetails} modifier={modifier} />
              </Modal>
            </>
          )}
        </div>
        <div className="time">
          <span className="caption">Ago</span>
        </div>
      </div>
    </div>
  )
}
export default SidebarHistoryHeader
